import {
    FaArchive,
    FaBell,
    FaCheckCircle,
    FaChevronRight,
    FaExclamationCircle,
    FaExclamationTriangle,
    FaFacebookF,
    FaFolder,
    FaFolderOpen,
    FaFolderPlus,
    FaInfoCircle,
    FaLinkedinIn,
    FaMinus,
    FaRegEnvelope,
    FaStar,
    FaTimesCircle,
    FaTwitter,
    FaUser,
    FaUserFriends
} from 'react-icons/fa';
import {
    FiAlertCircle,
    FiArrowLeft,
    FiArrowRight,
    FiArrowRightCircle,
    FiAtSign,
    FiCalendar,
    FiCheck,
    FiChevronDown,
    FiChevronLeft,
    FiChevronRight,
    FiChevronUp,
    FiChevronsUp,
    FiClock,
    FiCopy,
    FiCreditCard,
    FiDownload,
    FiEdit3,
    FiExternalLink,
    FiEye,
    FiEyeOff,
    FiFile,
    FiFilePlus,
    FiFilter,
    FiGlobe,
    FiHelpCircle,
    FiImage,
    FiInfo,
    FiLock,
    FiMail,
    FiMapPin,
    FiMaximize2,
    FiMessageSquare,
    FiMinus,
    FiMonitor,
    FiMoreHorizontal,
    FiPaperclip,
    FiPause,
    FiPlus,
    FiRefreshCcw,
    FiSearch,
    FiSettings,
    FiSmartphone,
    FiStar,
    FiTablet,
    FiThumbsUp,
    FiType,
    FiUnlock,
    FiUserCheck,
    FiUsers,
    FiX,
    FiZoomIn
} from 'react-icons/fi';
import { AiOutlineHolder } from 'react-icons/ai';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { RiCheckboxMultipleFill, RiCheckboxMultipleLine } from 'react-icons/ri';
import { GoTriangleRight } from 'react-icons/go';
import { LuChevronsUpDown } from 'react-icons/lu';

/**
 *
 * Icon library: https://react-icons.github.io/react-icons
 * Fi = "Feather" - main library
 * Fa = "Font Awesome" - for filled styles
 * All others (io5 = "Ionicons 5", Ri = "Remix", go = "Github Octicons", Lu = "Lucide")
 *
 */
export type IconName =
    | 'FaArchive'
    | 'FaBell'
    | 'FaCheckCircle'
    | 'FaChevronRight'
    | 'FaExclamationCircle'
    | 'FaExclamationTriangle'
    | 'FaFacebookF'
    | 'FaFolder'
    | 'FaFolderOpen'
    | 'FaFolderPlus'
    | 'FaInfoCircle'
    | 'FaLinkedinIn'
    | 'FaMinus'
    | 'FaRegEnvelope'
    | 'FaStar'
    | 'FaTimesCircle'
    | 'FaTwitter'
    | 'FaUser'
    | 'FaUserFriends'
    | 'FiAlertCircle'
    | 'FiArrowLeft'
    | 'FiArrowRight'
    | 'FiArrowRightCircle'
    | 'FiAtSign'
    | 'FiCalendar'
    | 'FiCheck'
    | 'FiChevronDown'
    | 'FiChevronLeft'
    | 'FiChevronRight'
    | 'FiChevronUp'
    | 'FiChevronsUp'
    | 'FiClock'
    | 'FiCopy'
    | 'FiCreditCard'
    | 'FiDownload'
    | 'FiEdit3'
    | 'FiExternalLink'
    | 'FiEye'
    | 'FiEyeOff'
    | 'FiFile'
    | 'FiFilePlus'
    | 'FiFilter'
    | 'FiGlobe'
    | 'FiHelpCircle'
    | 'FiImage'
    | 'FiInfo'
    | 'FiLock'
    | 'FiMail'
    | 'FiMapPin'
    | 'FiMaximize2'
    | 'FiMessageSquare'
    | 'FiMinus'
    | 'FiMonitor'
    | 'FiMoreHorizontal'
    | 'FiPaperclip'
    | 'FiPause'
    | 'FiPlus'
    | 'FiRefreshCcw'
    | 'FiSearch'
    | 'FiSettings'
    | 'FiSmartphone'
    | 'FiStar'
    | 'FiTablet'
    | 'FiThumbsUp'
    | 'FiType'
    | 'FiUnlock'
    | 'FiUserCheck'
    | 'FiUsers'
    | 'FiX'
    | 'FiZoomIn'
    | 'IoPaperPlaneOutline'
    | 'AiOutlineHolder'
    | 'RiCheckboxMultipleFill'
    | 'RiCheckboxMultipleLine'
    | 'GoTriangleRight'
    | 'LuChevronsUpDown';

export const icons = {
    FaArchive,
    FaBell,
    FaCheckCircle,
    FaChevronRight,
    FaExclamationCircle,
    FaExclamationTriangle,
    FaFacebookF,
    FaFolder,
    FaFolderOpen,
    FaFolderPlus,
    FaInfoCircle,
    FaLinkedinIn,
    FaMinus,
    FaRegEnvelope,
    FaStar,
    FaTimesCircle,
    FaTwitter,
    FaUser,
    FaUserFriends,
    FiAlertCircle,
    FiArrowLeft,
    FiArrowRight,
    FiArrowRightCircle,
    FiAtSign,
    FiCalendar,
    FiCheck,
    FiChevronDown,
    FiChevronLeft,
    FiChevronRight,
    FiChevronUp,
    FiChevronsUp,
    FiClock,
    FiCopy,
    FiCreditCard,
    FiDownload,
    FiEdit3,
    FiExternalLink,
    FiEye,
    FiEyeOff,
    FiFile,
    FiFilePlus,
    FiFilter,
    FiGlobe,
    FiHelpCircle,
    FiImage,
    FiInfo,
    FiLock,
    FiMail,
    FiMapPin,
    FiMaximize2,
    FiMessageSquare,
    FiMinus,
    FiMonitor,
    FiMoreHorizontal,
    FiPaperclip,
    FiPause,
    FiPlus,
    FiRefreshCcw,
    FiSearch,
    FiSettings,
    FiSmartphone,
    FiStar,
    FiTablet,
    FiThumbsUp,
    FiType,
    FiUnlock,
    FiUserCheck,
    FiUsers,
    FiX,
    FiZoomIn,
    IoPaperPlaneOutline,
    AiOutlineHolder,
    RiCheckboxMultipleFill,
    RiCheckboxMultipleLine,
    GoTriangleRight,
    LuChevronsUpDown
};
