import { AccessibleIcon } from '@radix-ui/react-accessible-icon';
import { tw } from 'twind';
import { icons, IconName } from 'lib/data/icons';

/**
 * Provides an easy & accessible way to use icons from the React Icons library.
 * name: The name of the icon you want to use.
 * className: Add additional styling using Twind, including color & margin.
 * label: Add a span with a label for users of assistive technology. Use when icons has semantic meaning.
 * size: Override default icon size.
 *
 * @export
 * @interface IconProps
 */
export interface IconProps {
    className?: string;
    label?: string;
    name: IconName;
    size?: number;
    strokeWidth?: number;
}

const Icon = ({ name, className, label, size, strokeWidth = 2 }: IconProps) => {
    const Icon = icons[name];
    const styles = tw('flex-shrink-0 inline-flex items-center', className);

    return Icon ? (
        label ? (
            <AccessibleIcon label={label}>
                <Icon
                    aria-hidden="true"
                    className={styles}
                    size={size ? `${size}em` : `1.2em`}
                    strokeWidth={strokeWidth}
                />
            </AccessibleIcon>
        ) : (
            <Icon aria-hidden="true" className={styles} size={size ? `${size}em` : `1.2em`} strokeWidth={strokeWidth} />
        )
    ) : null;
};

export default Icon;
